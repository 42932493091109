import React from 'react'

import geekwise from '../../assets/images/geekwise.jpg'
import landing from '../../assets/images/landing.png'
import signin from '../../assets/images/signin.png'
import order from '../../assets/images/order.png'
import photo from '../../assets/images/photo.png'
import myphotoform from '../../assets/images/myphotoform.png'



class Bitwise extends React.Component {
    render = () =>
        <React.Fragment>
            <div className="project-header">
                <div className="project-title">
                    Software Engineering (React Native) Apprenticeship @ Bitwise Industries
                </div>
                <div className="project-text">
                    Role: Lead UX/UI Developer 
                    <br/>
                    React Native, React, Angular, Node, Express, SQL, Postico, Sketch
                </div>
                <div className="tag">Premise</div>
                <div className="tag-explainer">
                    Apprenticeship is via Geekwise, started on April 2020 and is my current role.
                </div>
            </div>

            <div className="project-content">
                <div className="project-profile-pic">
                    <img src={geekwise} />
                </div>

                <div className="tag-divider">Purpose</div>
                <p className="h3">
                    To gain more understanding of React Native and software development
                </p>
                <div className="project-description">
                    The apprenticeship is under Bitwise's project called Geekwise. 
                </div>

                <div className="tag-divider-1">Intro to Consultancy</div>
                    <p className="h3"> At Bitiwse/Geekwise, we are developers and consultants </p>
                <div className="project-description">
                    The way our projects are given are based on clients. Without naming names,
                    we mainly worked on a festival app for a festival and we are currently working
                    on an app, web app, and admin dashboard for a virtual festival that will take 
                    place in Fall 2020.  We are all consultants and developers, often taking our 
                    own direction in order to meet deadlines.
                </div>

                <div className="tag-divider-1">First Month</div>
                    <p className="h3"> Our first warm-up project was a restaurant ordering app </p>
                    <img src={landing} />

                <p className="h3"> Log In Screen</p>
                <div className="project-description">
                    Using basic wireframes, we were asked to use bootstrap and React Native to 
                    build a restaurant ordering app. I used React Bootstrap for the buttons and 
                    forms. I used Unsplashed for the images.
                </div>
                    <img src={signin} />
                <p className="h3"> Sign In</p>

                <div className="project-description">
                    The sign in page icon was from Flat Icon. I chose the colors of this project 
                    based on this icon.
                </div>
                    <img src={order} />
                
                <p className="h3"> Form Functionality</p>
                <div className="project-description">
                    The ordering functionality is solid, although it is not attached to any backend.
                    There is the ability to create, update, and delete items from the menu. The 
                    GitHub Repo for this project can be found <a href="https://github.com/gabymoreno9/geekwise-restaurant-application">here</a>.
                </div>

                <div className="tag-divider-1">Midway Project</div>
                    <p className="h3">Between our two client projects, we had a fun challenge  </p>
                    <img src={photo} />
                    
                <p className="h3"> Challenge</p>
                <div className="project-description">
                    We were to build a single page registration form in React using the Bootstrap 
                    CSS grid. The page must be created using the provided design with a few 
                    exceptions. Also, a component has to be created for the header and the 
                    form on the design and there must be react hooks for the input forms.
                    <br/>
                    I found the original image that was used on Unsplashed. This is the result 
                    I was able to make.

                <p className="h3"> My Result </p>

                    <img src={myphotoform} />
                    The Github Repo for this project can be found <a href="https://github.com/gabymoreno9/oak-cohort-react-form">here</a>.
                </div>


            </div>
        </React.Fragment>
}


export default Bitwise
