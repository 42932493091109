import React from 'react'
import { Link } from 'react-router-dom'

import Navbar from './ui/Navbar'

import bitwise from '../assets/images/bitwise.png'
import pacoGo from '../assets/images/pacoGo.png'
import cssl from '../assets/images/CSSL.png'
import brightcove from '../assets/images/brightcove.png'
import memedex from '../assets/images/memedex.png'
import mux from '../assets/images/mux.png'
import techtonica from '../assets/images/techtonica.png'


class Home extends React.Component {
    render = () =>
        <React.Fragment>
            <div className="bio">
                Hi I'm Gabby. I like to design and develop cool stuff. 
                I'm currently a Software Engineer at Bitwise Technology Consulting.
            </div>

            <div className="portfolio-wrapper">

            <div className="card">
                    <Link to ="/portfolio/bitwise">
                        <img src={bitwise}/>
                    </Link>
                    <div className="card-text">
                        <p className="card-title">Apprentice @ Bitwise</p>
                        <div className="card-tag">Code</div>
                        <div className="card-tag">Design</div>
                        <p className="h5 card-subtitle">
                            Led the UX/UI of my React Native Apprenticeship cohort.
                        </p>
                    </div>
                </div>

                <div className="card">
                    <Link to ="/portfolio/pacogo">
                        <img src={pacoGo}/>
                    </Link>
                    <div className="card-text">
                        <p className="card-title">PacoGo</p>
                        <div className="card-tag">Code</div>
                        <div className="card-tag">Design</div>
                        <p className="h5 card-subtitle">
                            Developed a coding game for elementary school kids, built as final project @ Techtonica.
                        </p>
                    </div>
                </div>

            
                <div className="card">
                <Link to="/portfolio/techtonica">
                    <img src={techtonica}/>
                </Link>
                    <div className="card-text">
                        <p className="card-title">Apprentice @ Techtonica</p>
                        <div className="card-tag">Code</div>
                        <div className="card-tag">Design</div>
                        <p className="h5 card-subtitle">
                            Learned about SWE and collaborated to open source projects.
                        </p>
                    </div>
                </div>

                <div className="card">
                    <img src={mux}/>
                    <div className="card-text">
                        <p className="card-title">Marketing @ Mux</p>
                        <div className="card-tag">Marketing</div>
                        <div className="card-tag">SEO</div>
                        <p className="h5 card-subtitle">
                            Assisting Marketing team through SEO and blog projects.
                        </p>
                    </div>
                </div>


                <div className="card">
                    <Link to="/portfolio/memedex">
                        <img src={memedex} />
                    </Link>
                    <div className="card-text">
                        <p className="card-title">Memedex.io</p>
                        <div className="card-tag">Code</div>
                        <div className="card-tag">Design</div>
                        <p className="h5 card-subtitle">
                            Designed and developed a meme and Vine search engine.


                        </p>
                    </div>
                </div>

                <div className="card">
                    <Link to="/portfolio/brightcove">
                        <img src={brightcove} />
                    </Link>
                    <div className="card-text">
                        <p className="card-title">Product Marketing @ Brightcove</p>
                        <div className="card-tag">Code</div>
                        <div className="card-tag" style={{ clear: "right" }}>Marketing</div>
                        <p className="h5 card-subtitle">
                            Applied design and coding skills to create mock ups for
                            sales team.
                        </p>
                    </div>
                </div>

                <div className="card">
                    <Link to="/portfolio/Cssl">
                        <img src={cssl} />
                    </Link>
                    <div className="card-text">
                        <p className="card-title"> CSSL Lab</p>
                        <div className="card-tag">Research</div>
                        <div className="card-tag">Sheets</div>
                        <p className="h5 card-subtitle">
                            Assisted in natural language processing research
                            at USC.
                        </p>
                    </div>
                </div>
            </div>
        </React.Fragment>
}


export default Home
