import React from 'react'
import Favicon from 'react-favicon'
import { Route, Switch, withRouter } from 'react-router-dom'

import Home from './components/Home'
import About from './components/About'
import Portfolio from './components/Portfolio'
import Navbar from './components/ui/Navbar'

import favicon from './assets/images/favicon.png'
import './assets/css/index.css'


class App extends React.Component {
    state = { darkMode: false }

    componentDidUpdate (oldProps) {
        if (this.props.location.pathname !== oldProps.location.pathname) {
            window.scrollTo(0, 0)
        }
    }

    render = () =>
        <div className={`page-container ${this.state.darkMode ? "dark" : "light"}`}>
            <Favicon url={favicon} />

            <Navbar />

            <Switch>
                <Route path="/portfolio" component={Portfolio} />
                <Route path="/about" component={About} />
                <Route path="/" component={Home} />
            </Switch>

            <div className="dark-mode-switch-wrapper">
                <div className="label">Use Dark Mode</div>
                <div className="dark-mode-switch"
                     onClick={() => this.setState({ darkMode: !this.state.darkMode })}>
                    <div className={`toggle ${this.state.darkMode ? "right" : "left"}`} />
                </div>
            </div>
        </div>
}


export default withRouter(App)
