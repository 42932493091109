import React from 'react'

import cssl2 from '../../assets/images/cssl2.png'


class Cssl extends React.Component {
    render = () =>
        <React.Fragment>
            <div className="project-header">
                <div className="project-title">
                    Research Assistanceship at the <br/>CSSL Lab at USC
                </div>
                <div className="project-text">
                    Role: Research Assistant
                    <br/>
                    Tools: Google Sheets, Git
                </div>
                <div className="tag">Premise</div>
                <div className="tag-explainer">
                    My tasks were to read, process, and tag snippets of <br/>
                    tweets pertaining to a variety of topics <br/> from social
                    justice, natural disasters, to police brutality.
                </div>
            </div>

            <div className="project-content">
                <div className="project-profile-pic">
                    <img src={cssl2} />
                </div>

                <div className="tag-divider">Purpose</div>
                <p className="h3">
                        Moral sentiment in natural language can help explain why
                        people do the things they do in real life and online.

                </p>
                <div className="project-description">
                Sometimes people feel inspired and protest when they feel
                a disjustice has occured and some people never leave their
                house. We know that natural language
                can help explain people's behaviors, but there is not
                enough data to really investigate it. That's where CSSL
                comes in. Because we want to understand people through
                natural language we have to try to analyze tons of it.
                Tons of it.
                </div>

                <div className="tag-divider-1">Moral Foundations Twitter Corpus</div>
                    <p className="h3">
                        This is where CSSL comes in.
                    </p>
                <div className="project-description">
                    We developed the Moral Foundations Twitter Corpus. By using
                    tweets from several domains of discourse such as the #MeToo
                    movement, Black Lives Matter, the Baltimore protests, and
                    Hurricane Sandy, we have more relevant data. Because, moral
                    sentiment occurs in moral relevant discourse context, you
                    can gauge a person's moral sentiment accurately when morally
                    relevants are happening in the present. I essentially tagged
                    each tweet into one of ten categories of moral sentiment. The
                    moral sentiments were paired with it's opposite. For example,
                    "care/harm", "fairness/cheating", "loyalty/betrayal",
                    "authority/subversion", and "purity/degradation".
                </div>

            </div>
        </React.Fragment>
}


export default Cssl
