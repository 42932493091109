import React from 'react'

import brightcove1 from '../../assets/images/brightcove1.png'
import aircanada from '../../assets/images/aircanada.gif'


class Brightcove extends React.Component {
    
    render = () =>
        <React.Fragment>
            <div className="project-header">
                <div className="project-title">
                    Brightcove Product Marketing <br/>Internship
                </div>
                <div className="project-text">
                    Role: Product Marketing Co-Op Intern
                    <br/>
                    Team: Product Marketing (Best Team Ever™)
                    <br/>
                    Tools: HTML/CSS, Javascript, Google Sheets, Salesforce, Final Cut Pro
                </div>
                <div className="tag">Premise</div>
                <div className="tag-explainer">
                    Applied web development skills to the creation of <br/>proof of
                    concept prototypes to impact revenue.
                </div>
            </div>

            <div className="project-content">
                <div className="project-profile-pic">
                    <img src={brightcove1} />
                </div>

                <div className="tag-divider">Product Marketer</div>
                <p className="h3">
                    As a Product Marketing Intern at Brightcove, I created Gallery
                    site prototypes and collaborated across product marketing and
                    sales teams.
                </p>
                <div className="project-description">
                    I was a Product Marketing Co-Op intern for Brightcove from
                    July 2018 to May 2019. I created proof of concept sites that
                     sales managers would use during meeting with clients. I
                     grabbed the header and footer of the sites along with their
                     logo and other media to complete the gallery site.
                     Establishing communication with account executives to better
                     understand the POC is important. SEO, responsive design,
                     social sharing, and cohesive UI are skills I gained
                     throughout this internship.
                </div>

                <div className="tag-divider">Gallery Portal</div>
                    <p className="h3">
                        Gallery portals are fancy sites that clients can choose
                        to display their videos.
                    </p>
                <div className="project-description">
                    Some portals are best for big
                    video libraries and some are better suited for showcasing
                    fewer videos with a specific layout and design. Inside
                    of the videos, there are interactive card elements that
                    appear to the user in the top right or left hand corner
                    like a pop-up. This is especially useful for clients who
                    want to present a link to the users during a video.
                </div>


                <div className="tag-divider">Live Events</div>
                <img src={aircanada} />
                <p className="h3">
                    Live events are sites that are specialized for events
                    where companies could have a live stream complete with
                    optional chat features and social sharing.
                </p>

                <div className="tag-divider-1">Gallery Live Sites List</div>
                <p className="h3">
                    The gallery live site list is a list of sites that are
                    online. One of my jobs was to update the site list as
                    new POCs were created. The list was maintained by me
                    weekly on an excel file.
                </p>

                <div className="tag-divider">SEO</div>
                <p className="h3">
                    The general idea of SEO is the strategic selection of keywords
                    and metatags that allow for your content to show up at the top of
                    search results.
                </p>
                    <div className="project-description">
                        At Brightcove I learned that to have a successful
                        video SEO strategy you will need:
                            <ol>
                                <li>A unique URL for each video</li>
                                <li>Schema tags for each video</li>
                                <li>a video sitemap</li>
                            </ol>
                    </div>

                <div className="tag-divider-1">Brightcove Intern Portal</div>
                <p className="h3">
                    One of my favorite projects was designing the intern
                    portal for the next intern. I wanted to make something
                    interactive and comprehensive to guide the future intern.
                    I edited and shot the short video using Final Cut Pro.
                    View the video below:
                    <br/>
                    <br/>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/JD1flw88M9k"
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen />
                </p>
            </div>
        </React.Fragment>
}


export default Brightcove
