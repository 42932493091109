import React from 'react'

import paquito from '../../assets/images/paquito.gif'
import pacomap from '../../assets/images/pacomap.png'
import pacohome from '../../assets/images/pacohome.png'
import pacohome1 from '../../assets/images/pacohome1.png'
import userflow from '../../assets/images/userflow.png'




class PacoGo extends React.Component {
    render = () =>
        <React.Fragment>
            <div className="project-header">
                <div className="project-title">
                    PacoGo! 
                </div>
                <div className="project-text">
                    Role: Developer and designer
                    <br/>
                    React, Sketch
                </div>
                <div className="tag">Premise</div>
                <div className="tag-explainer">
                PacoGo is a project I made during my time at Techtonica. It is a 
                game designed for Elementary school aged kids to teach them
                computational thinking. The goal is to get Paco back to his home
                by  dragging actions to guide him.
                </div>
            </div>

            <div className="project-content">
                <div className="project-profile-pic">
                    <img src={paquito} />
                </div>

                <div className="tag-divider">Purpose</div>
                <p className="h3">
                        Growing up I loved playing computer games. 

                </p>
                <div className="project-description">
                I wanted to create
                        some sort of game that kids could play that could secretly 
                        teach them valuable computational skills such as pattern recognition,
                        decomposition, abstraction or data representation, and algorithms. I decided
                        on teaching computational thinking instead of math or typing because its a
                        useful skillset for solving any problems. Pattern recognition is useful once pieces of a problem are 
                        broken down (decomposition) and similaries or shared characteristics of 
                        previous solved problems are obvious. Decomposition is the breaking down of 
                        a problem into something more manageable, usually smaller problems. 
                        At this point, only a simple level of PacoGo is available, but eventually,
                        levels will get more difficult and more obstacles will be in the path of
                        Paco. Eventually, tasks like if/then statements will necessary to get Paco
                        home. An example would be if there is a cactus on shortest path home, then 
                        use the Mighty Pizza™ to take the damage and get Paco across.
                </div>

                <div className="tag-divider-1">Design</div>
                    <p className="h3">
                        It started with a shiba. 
                    </p>
                <div className="project-description">
                        Inspired by <a href="https://www.youtube.com/watch?v=bjwRQ71A6C0">Tofu Chan</a>, 
                        I decided to make the main character of the game a Shiba. It just so happened that
                        Flat Icon already had an adorable shiba puppy SVG available for use. 
                       
                    <p className="h3">Early Designs </p>
                    <img src={pacohome1} />
                        This design was created using Balsamiq.
                        Initially I wanted PacoGo to have a sign in screen for students and teachers. Unfortunately,
                        there was only a month to complete this project, and creating an authentication system
                        for students and teachers would have resulted in Paco going nowhere. For the future,
                        I would like implement a sign in for students and teachers and a system for teachers
                        to view how students are doing. I would create a dashboard for teachers to view where 
                        students are getting stuck and get a better idea  on how to create lesson plans covering
                        pattern recognition, for example, in their daily lessons.

                    <p className="h3">PacoGo Redesigned</p>
                    <img src={pacohome} />
                        Later I decided on a simpler home screen where Paco can be front and center.

                    <p className="h3">PacoGo's World</p>
                    <img src={pacomap} />
                        Paco's world consists of a grid that gets bigger and more full of obstacles
                        as the player wins through levels.
                    
                    <p className="h3">Supporting Docs</p>
                        <a href="https://github.com/gabymoreno9/PacoGo">GitHub Repo</a>, <a href="https://trello.com/b/Tl544AkH/pacogo">Trello Board</a>, and
                        <a href="https://docs.google.com/document/d/1_qapej-GwTKX1vqEojnWcYB2Fm5URmYM3AdLmSVfnMA/edit"> Google Doc</a>. You can play the first
                        level of PacoGo <a href="http://www.pacogo.com">here</a>.


                </div>


            </div>
        </React.Fragment>
}


export default PacoGo
