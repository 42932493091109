import React from 'react'
import { Route, Switch } from 'react-router-dom'

import Navbar from './ui/Navbar'
import Brightcove from './portfolio-projects/Brightcove'
import Memedex from './portfolio-projects/Memedex'
import Cssl from './portfolio-projects/Cssl'
import Techtonica from './portfolio-projects/Techtonica'
import PacoGo from './portfolio-projects/PacoGo'
import Bitwise from './portfolio-projects/Bitwise'



class Portfolio extends React.Component {
    render = () =>
        <Switch>
            <Route path="/portfolio/brightcove" component={Brightcove} />
            <Route path="/portfolio/memedex" component={Memedex} />
            <Route path="/portfolio/cssl" component={Cssl} />
            <Route path="/portfolio/techtonica" component={Techtonica} />
            <Route path="/portfolio/pacogo" component={PacoGo} />
            <Route path="/portfolio/bitwise" component={Bitwise} />


        </Switch>
}


export default Portfolio
