import React from 'react'

import memedex from '../../assets/images/memedex.png'
import memedexlogo1 from '../../assets/images/memedexlogo1.png'
import earlymemedex from '../../assets/images/earlymemedex.png'


class Memedex extends React.Component {
    componentDidMount() {
        let metaTag = document.createElement("meta");
        metaTag.setAttribute("name", "og:image");
        metaTag.setAttribute("content", memedexlogo1);
        document.querySelector("head").appendChild(metaTag);
    }

    render = () =>
        <React.Fragment>
            <div className="project-header">
                <div className="project-title">
                    Finally, a meme search engine
                </div>
                <div className="project-text">
                    Role: Designer and developer
                    <br/>
                    Tools: HTML/CSS, Javascript, React, Sketch
                    <br/>
                    <a href="https://github.com/gabymoreno9/memedex">Github</a>
                </div>
                <div className="tag">Premise</div>
                <div className="tag-explainer">
                    Applied web development skills to the creation of <br/>a search
                    engine that could find all those hidden vines <br/>that still
                    pop in your head sometimes
                </div>
            </div>

            <div className="project-content">
                <div className="project-profile-pic">
                    <img src={memedexlogo1} />
                </div>

                <div className="tag-divider-1">Designer and Developer</div>
                <p className="h3">
                    There should be a better way to discover and search for
                    memes like Vines and TikToks.
                </p>
                <div className="project-description">
                    This project started when I was unable to remember a
                    specific vine video, it ended up being this <a href="http://www.memedex.io/watch/1063376625703542784">
                    one</a> by the way, and I was so frustrated because Vine was purchased by Twitter
                    and Twitter did not do a good job at upkeeping it or its
                    archive. The site doesn't even have a search bar and requires
                    users to type into the URL bar the username of the creator
                    of the video. It gets worse. There's no way to sort the videos
                    once you remember the creator's username. That is IF you
                    remember it because it very well could have been changed as
                    many users changed their usernames towards the end of Vine's
                    reign. So I created a meme search engine and designed the look and
                    feel of memedex.io. It is an ongoing project that started the
                    Summer after I graduated college.
                </div>

        <div className="tag-divider">Design</div>
                    <img src={earlymemedex} />
                    <p className="h3">
                        At first, I wanted to make a monochrome style search page
                        with black font and white background.
                    </p>

                <div className="project-description">
                        Then I decided to maybe
                        experiment with some color on the logo.
                </div>
                <p className="h3">Sorting</p>
                <div className="project-description">
                    First things first was the sort drop down. Sorting was something
                    I really wanted because while a user can remember the creator, it
                    may take a couple minutes to scroll down to find their most popular
                    vine if there is no sorting ability.
                </div>

                <p className="h3">Index</p>
                <div className="project-description">
                     I knew I wanted to make the landing page super simple and clean
                     after looking at what other similar sites were doing on their
                     page. I wanted the purpose of the site to be very clear in the
                     name of and on the search bar.
                </div>

                <p className="h3">Loading</p>
                <div className="project-description">
                    I didn't want a basic looking icon or animation that indicated
                    a page was loading so I found some dolphin and
                    porthole clip art from FlatIcon and animated it to move
                    while the page is loading. I am not sure if the
                    dolphin will become the unofficial Memedex mascot but it
                    was fun to animate. His name is Spinner.
                </div>


        <div className="tag-divider">How its Made</div>
                <p className="h3">
                    Part 1: Scrape
                </p>
                <div className="project-description">
                    First we had to figure out how the videos were organized on
                    the site. I used the network tab to figure out how Vine's
                    API works and what API endpoints the browser has to call to
                    show the video. I and looked at the
                    network tab and saw the JSON files on every page that has all the
                    data. The JSON files had the post and a unique ID for every video.
                    So, I wrote a Python script that would first go to the user's
                    page and then go to each unique video and download the unique
                    ID of every video onto a ID.JSON file. So, all the JSON files are
                    fetched and merged into one file per user. All the JSON files
                    of the individual videos are put into one array, to one file,
                    one per each user.
                </div>

                <p className="h3">Part 2: Typesense</p>
                <div className="project-description">
                    Initially, I was going to use Algolia for the actual search
                    engine. But I figured out quickly that we had too much data
                    and passed the limit for their free trial. Next idea was to
                    look for other budget friendly alternatives.
                    <a href="https://typesense.org/0.10.0/api/"> Typesense</a> worked perfectly.
                    It's even friendly to typos!
                </div>

                <p className="h3">Part 3: I smell like beef</p>
                <div className="project-description">
                    If you have watched vines, you may be able to tell what the title
                    of this part is in <a href="http://www.memedex.io/watch/1083968130537218048">reference to</a>. The problem with some videos is that
                    Typesense uses the description and data from the JSON files to
                    sort through the videos and not the content itself. So when searching
                    for some videos, users may not be able to actually find them because
                    the audio (what users usually use to search) is not transcribed into the JSON files. So what I needed
                    to do is transcribe them. At first I tested Google Cloud Speech-to-text.
                    With some noisy vines, it was difficult for it to actually listen
                    to what the audio was saying, so that was not going to work. Eventually I figured it was more
                    efficient to manually caption popular vines for now. I wrote a
                    script that would take my captions and input them into the JSON file of
                    that specific video, so when a user searches, "I smell like beef", it
                    is easier to find.
                </div>






            </div>




        </React.Fragment>
}


export default Memedex
