import React from 'react'

import indeed from '../../assets/images/indeed.jpg'
import paco1 from '../../assets/images/paco1.png'
import paco from '../../assets/images/paco.png'
import boggle from '../../assets/images/boggle.png'



class Techtonica extends React.Component {
    render = () =>
        <React.Fragment>
            <div className="project-header">
                <div className="project-title">
                    Software Engineering Apprenticeship <br/> at Techtonica
                </div>
                <div className="project-text">
                    Role: Software Engineering Apprentice
                    <br/>
                    JS, Node, React, React Native, HTML/CSS, Express, PostgreSQL
                </div>
                <div className="tag">Premise</div>
                <div className="tag-explainer">
                Techtonica is six months of full-time, paid, hands-on, project-based, 
                collaborative software engineering learning with a focus on full-stack 
                JavaScript. The last month is spent preparing to join Techtonica’s 
                partner companies for employment.
                </div>
            </div>

            <div className="project-content">
                <div className="project-profile-pic">
                    <img src={indeed} />
                </div>

                <div className="tag-divider">Purpose</div>
                <p className="h3">
                        I joined Techtonica in order to gain more formalized experience
                        in software engineering.

                </p>
                <div className="project-description">
                    My time in Techtonica formally started in December of 2019. I met with 
                    the cohort members to go over our pre-coursework over December. We completed
                    the Treehouse lessons in JavaScript. We started the program on the first
                    week of January and learned all about web development. 
                </div>

                <div className="tag-divider-1">Boggle Game</div>
                    <img src={boggle} />
                    <p className="h3">
                        My take on Boggle
                    </p>
                <div className="project-description">
                    One of the most fun projects I did while at Techtonica
                    was design and make a Boggle game. It includes a timer,
                    highlighting of the letters to let the user know what 
                    they clicked on, and a running list of words the  user has
                    found. In the future, I'd like to create a better UI for the 
                    clicking and dragging. I'm thinking an illuminated path
                    that is created by the user as they click and drag.
                    The GitHub repo for this project can be found 
                    <a href= "https://github.com/gabymoreno9/Assignments/tree/master/Boggle"> here</a>.
                </div>

                <div className="tag-divider-1">Open Source Contributions</div>
                    <p className="h3">
                        Techtonica's curriculum is Open Sourced
                    </p>
                <div className="project-description">
                    This means there were plenty of opportunities for me to
                    get acquainted with making PR's. To get practice, I looked
                    for spelling mistakes, broken links, and continuity errors 
                    in our lesson plans every day so I could make a PR.
                </div>

                <div className="tag-divider-1">PacoGo</div>
                    <img src={paco} />
                    <p className="h3">
                        My Final Project
                    </p>
                <div className="project-description">
                    PacoGo is a 'coding' game designed to get elementary school 
                    aged kids to get into the groove of computational thinking. I am a huge
                    fan of MIT Media Lab's Scratch programming language, so I wanted to 
                    see if I could build something like that. Turns out the draggable colorful
                    puzzle pieces they use are <a href ="https://developers.google.com/blockly/">open source</a>, but are incredibly frustrating
                    to implement. I found a more friendly alternative for my project and it is 
                    a great click and drag styled IDE for now. 
                    The GitHub of the project is availble <a href="https://github.com/gabymoreno9/PacoGo"> here</a>.
                    Version 1 of the game can be played <a href = "http://www.pacogo.com"> here</a>.
                </div>
                <div className="tag-divider-1">Other Projects</div>
                    Projects done throughout the program can be found in this <a href="https://github.com/gabymoreno9/Assignments">repo</a>.

            </div>
        </React.Fragment>
}


export default Techtonica
