import React from 'react'

import gibbyyy from '../assets/images/gibbyyy.jpg'


class About extends React.Component {
    render = () =>
        <React.Fragment>

    <div className="about-page">
        <div className="aboutpic"><img src={gibbyyy} /></div>

        <div className="about-content">
            <div className="page-header"> Hello, I'm Gabby </div>

            <div className="side-info">
                gabrielaxmoreno@gmail.com&nbsp;•&nbsp;
                <a href="https://www.linkedin.com/in/gabriela-moreno-a58320114/">LinkedIn</a>
               
                
                <br />
                <br />
            </div>

            <div className="about-bio">
                {/* I attended the University of Southern California and majored in
                cognitive science. The interdisciplinary program lead me to take
                classes on philosophy, linguistics, computer science, and user
                experience design.
                <br />
                <br /> */}
            </div>
            <div className="about-bio">
                After attending a coding bootcamp in the beginning
                of 2020, I discovered user experience and front-end development bring me joy. I am currently
                a Software Engineer at Bitwise where I lead the UI 
                development of my team. In the evenings I also teach introduction to web development to folks who have never touched tech. My previous roles involve
                product marketing, demand generation, and tech support. I also am a self proclaimed <a href = "memedex.io" >meme historian</a>. 
                
                
                
            </div>
            </div>
        </div>
        </React.Fragment>
}


export default About
